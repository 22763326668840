<template>
  <div>
    <div class="row mb-1">
      <div class="col">
        <date-picker
          v-model="form"
          :masks="{ input: 'D/M/YYYY' }"
          mode="date"
          @input="datepicked"
          is-range
        >
          <template v-slot="{ inputValue, togglePopover }">
            <!-- :mode="date-time" -->
            <b-form-input
              class="border rounded"
              :value="`${inputValue.start} - ${inputValue.end}`"
              @click="togglePopover()"
              autocomplete="off"
            />
          </template>
        </date-picker>
      </div>
    </div>
    <div class="row">
      <div class="col-2">
        <b-button
          class="mr-1 mb-1"
          variant="relief-secondary"
          @click="previous"
          size="sm"
          block
        >
          <
        </b-button>
      </div>
      <div class="col d-flex justify-content-between">
        <b-button
          class="mr-1 mb-1"
          :variant="
            currentMode === 'day' ? 'relief-success' : 'relief-secondary'
          "
          @click="selectDay"
          size="sm"
        >
          D
        </b-button>
        <b-button
          class="mr-1 mb-1"
          :variant="
            currentMode === 'week' ? 'relief-success' : 'relief-secondary'
          "
          @click="selectWeek"
          size="sm"
        >
          W
        </b-button>
        <b-button
          class="mr-1 mb-1"
          :variant="
            currentMode === 'month' ? 'relief-success' : 'relief-secondary'
          "
          @click="selectMonth"
          size="sm"
        >
          M
        </b-button>
        <b-button
          class="mr-1 mb-1"
          :variant="
            currentMode === 'year' ? 'relief-success' : 'relief-secondary'
          "
          @click="selectYear"
          size="sm"
        >
          Y
        </b-button>
      </div>
      <div class="col-2">
        <b-button
          class="mr-1 mb-1"
          variant="relief-secondary"
          @click="next"
          size="sm"
          block
        >
          >
        </b-button>
      </div>
      <!-- <div class="col"></div>
      <div class="col"></div>
      <div class="col"></div>
      <div class="col"></div>
      <div class="col"></div> -->
    </div>
  </div>
</template>

<script>
import { BButton, BFormGroup, BFormInput } from "bootstrap-vue";
import DatePicker from "v-calendar/lib/components/date-picker.umd";
import moment from "moment";
import "moment-timezone";

export default {
  components: {
    DatePicker,
    BFormGroup,
    BFormInput,
    BButton,
  },
  data() {
    return {
      form: {
        // date_range: {
        start: new Date(),
        end: new Date(),
        // start: new Date("2023-11-12"),
        // end: new Date("2023-11-12"),
        // },
      },
      currentMode: "day",
      modelConfig: {
        type: "string",
        mask: "YYYY-MM-DD", // Uses 'iso' if missing
      },
    };
  },
  created() {
    this.datepicked();
  },
  methods: {
    previous() {
      this.adjustDate(-1);
    },

    next() {
      this.adjustDate(1);
    },
    datepicked() {
      this.$emit("date_picked", this.form);
    },
    selectDay() {
      this.currentMode = "day";
      const today = new Date();
      this.updateFormDate(today, today);
    },
    selectWeek() {
      this.currentMode = "week";
      const today = new Date();
      const dayOfWeek = today.getDay();
      const mondayOffset = dayOfWeek === 0 ? -6 : 1 - dayOfWeek; // Adjust for weeks starting on Monday
      const firstDayOfWeek = new Date(today);
      firstDayOfWeek.setDate(today.getDate() + mondayOffset);

      const lastDayOfWeek = new Date(firstDayOfWeek);
      lastDayOfWeek.setDate(lastDayOfWeek.getDate() + 6);

      this.updateFormDate(firstDayOfWeek, lastDayOfWeek);
    },
    selectMonth() {
      this.currentMode = "month";
      const today = new Date();
      const firstDayOfMonth = new Date(
        today.getFullYear(),
        today.getMonth(),
        1
      );
      const lastDayOfMonth = new Date(
        today.getFullYear(),
        today.getMonth() + 1,
        0
      );

      this.updateFormDate(firstDayOfMonth, lastDayOfMonth);
    },
    selectYear() {
      this.currentMode = "year";
      const today = new Date();
      const firstDayOfYear = new Date(today.getFullYear(), 0, 1);
      const lastDayOfYear = new Date(today.getFullYear(), 11, 31);
      this.updateFormDate(firstDayOfYear, lastDayOfYear);
    },

    updateFormDate(start, end) {
      this.form = { ...this.form, start: new Date(start), end: new Date(end) };
      this.datepicked();
    },
    adjustDate(direction) {
      let start = new Date(this.form.start);
      let end = new Date(this.form.end);

      switch (this.currentMode) {
        case "day":
          start.setDate(start.getDate() + direction);
          end = new Date(start);
          break;
        case "week":
          start.setDate(start.getDate() + direction * 7);
          end.setDate(end.getDate() + direction * 7);
          break;
        case "month":
          start.setMonth(start.getMonth() + direction);
          end = new Date(start.getFullYear(), start.getMonth() + 1, 0);
          break;
        case "year":
          start.setFullYear(start.getFullYear() + direction);
          end = new Date(start.getFullYear(), 11, 31);
          break;
      }

      this.updateFormDate(start, end);
    },
  },
};
</script>
