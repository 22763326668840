<template>
  <div>
    <table class="table">
      <thead>
        <tr>
          <th>ID</th>
          <th>Name</th>
          <th>Age</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="x in data" :key="x.id">
          <td>{{ x.bookbankDetails.display_name }}</td>
          <td>{{ x.member.retro_username }} {{ x.member.gen_username }}</td>
          <td>{{ x.amount }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  components: {},
  props: ["data"],
  data() {
    return {};
  },
  methods: {},
};
</script>
