<template>
  <div>
    <vue-good-table
      :columns="columns"
      :rows="rows"
      :pagination-options="{
        enabled: true,
        perPage: pageLength,
      }"
    >
      <template slot="table-row" slot-scope="props">
        <span v-if="props.column.field === 'member'">
          <router-link :to="`/order/view/${props.row._id}`">
            {{ props.row.order_id }}
          </router-link>
          <div v-if="props.row.member">
            <!-- <router-link :to="`/member/view/${props.row.member._id}`"> -->
            <!-- {{ props.row.member.member_id }} -->
            {{ props.row.member.first_name }}
            {{ props.row.member.last_name }}
            <!-- </router-link> -->
            <br />
            {{ props.row.member.phone }} <br />
            {{ props.row.member.email }}
          </div>
          <div v-else>
            <p>ไม่มีข้อมูลลูกค้า</p>
          </div>
        </span>
        <span v-else-if="props.column.field === 'income'">
          <div class="d-flex justify-content-between">
            <span>ค่าเช่า</span>
            <span>{{ props.row.rental_price | number }}</span>
          </div>
          <div class="d-flex justify-content-between">
            <span>ค่าส่ง</span>
            <span>{{ props.row.shipping_price | number }}</span>
          </div>
          <div class="d-flex justify-content-between">
            <span>ส่วนลด</span>
            <span>{{ props.row.discount_price | number }}</span>
          </div>
          <div class="d-flex justify-content-between">
            <span>รวม</span>
            <span>{{ net_price(props.row) | number }}</span>
          </div>
          <div class="d-flex justify-content-between">
            <span>ค่าประกัน</span>
            <span>{{ props.row.deposit_price | number }}</span>
          </div>
        </span>
        <span v-else-if="props.column.field === 'paid'">
          <div class="d-flex justify-content-between">
            <span>ค่าเช่า</span>
            <span>{{ props.row.sum_paid_rental | number }}</span>
          </div>
          <div class="d-flex justify-content-between">
            <span>ค่าส่ง</span>
            <span>{{ props.row.sum_paid_shipping | number }}</span>
          </div>
          <br />
          <div class="d-flex justify-content-between">
            <span>รวม</span>
            <span>{{ props.row.sum_paid_income | number }}</span>
          </div>

          <div class="d-flex justify-content-between">
            <span>ค่าประกัน</span>
            <span>{{ props.row.sum_paid_deposit | number }}</span>
          </div>
          <hr />
          <div class="d-flex justify-content-between">
            <span
              ><b-badge
                variant="danger"
                class="text-white"
                v-if="check_total_paid(props.row)"
                >รับเงินไม่ครบ</b-badge
              ></span
            >
            <span>{{ props.row.sum_paid | number }}</span>
          </div>
        </span>
      </template>

      <template slot="pagination-bottom" slot-scope="props">
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap"> Showing 1 to {{ props.total }}</span>
            <b-form-select
              v-model="pageLength"
              :options="['5', '10', '25']"
              class="mx-1"
              @input="
                (value) => props.perPageChanged({ currentPerPage: value })
              "
            />
          </div>
          <div>
            <b-pagination
              :value="1"
              :total-rows="props.total"
              :per-page="pageLength"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
              @input="(value) => props.pageChanged({ currentPage: value })"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>

    sumPaidRental: {{ sumPaidRental }} <br />
    sumPaidShipping: {{ sumPaidShipping }} <br />
    sumPaidDeposit:{{ sumPaidDeposit }} <br />
    <!-- sumOrderRental : {{ sumOrderRental }} <br />
    sumOrderShipping: {{ sumOrderShipping }} <br />
    sumOrderDeposit: {{ sumOrderDeposit }} <br /> -->
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BButton,
  BAvatar,
  BBadge,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BDropdown,
  BDropdownItem,
} from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";
import _ from "lodash";

export default {
  components: {
    BButton,
    VueGoodTable,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BRow,
    BCol,
  },
  computed: {
    // table_sum_rental() {
    //   // Calculate the total based on rental_price and deposit_price
    //   return this.data.reduce((total, item) => {
    //     console.log("sum_paid_rental", item.sum_paid_rental);

    //     return {
    //       rental: total + item.sum_paid_rental,
    //     };
    //   }, 0);
    // },
    sumPaidRental() {
      return this.data.reduce(
        (total, payment) => total + payment.sum_paid_rental,
        0
      );
    },
    sumPaidShipping() {
      return this.data.reduce(
        (total, payment) => total + payment.sum_paid_shipping,
        0
      );
    },
    sumPaidDeposit() {
      return this.data.reduce(
        (total, payment) => total + payment.sum_paid_deposit,
        0
      );
    },

    sumOrderRental() {
      return this.data.reduce(
        (total, payment) => total + payment.rental_price,
        0
      );
    },
    sumOrderShipping() {
      return this.data.reduce(
        (total, payment) => total + payment.shipping_price,
        0
      );
    },
    sumOrderDeposit() {
      return this.data.reduce(
        (total, payment) => total + payment.deposit_price,
        0
      );
    },

    rows() {
      if (this.data) {
        return this.data;
      } else {
        return [];
      }
    },
  },
  props: ["data"],
  data() {
    return {
      // rows: [],
      pageLength: 25,
      columns: [
        // {
        //   label: "Order_id",
        //   field: "order_id",
        // },
        {
          label: "ลูกค้า",
          field: "member",
        },
        {
          label: "รายรับ",
          field: "income",
        },
        {
          label: "PAID",
          field: "paid",
        },
      ],
    };
  },
  created() {
    // this.rows = this.data
  },
  methods: {
    net_price(row) {
      return (
        _.get(row, "rental_price", 0) +
        _.get(row, "shipping_price", 0) -
        _.get(row, "discount_price", 0)
      );
    },
    net_paid(row) {
      return (
        _.get(row, "rental_price", 0) +
        _.get(row, "shipping_price", 0) -
        _.get(row, "discount_price", 0)
      );
    },
    check_total_paid(row) {
      const order =
        _.get(row, "rental_price", 0) +
        _.get(row, "shipping_price", 0) -
        _.get(row, "discount_price", 0) +
        _.get(row, "deposit_price", 0);
      const paid = row.sum_paid;

      // console.log("order / paid ", order, paid);
      if (order === paid) {
        return false;
      } else {
        return true;
      }
    },
  },
  filters: {
    number: function (x) {
      if (x != null) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      } else {
        return x;
      }
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
