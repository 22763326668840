<template>
  <div>
    <div class="table-responsive">
      <table class="table table-hover">
        <thead>
          <tr>
            <th>บัญชี</th>
            <th>ยอดเงินคงเหลือ</th>
            <th>ยังไม่ได้ตัดยอด</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(row, index) in account_list" :key="index">
            <td>{{ row.display_name }}</td>
            <td>{{ row.balance_main | number }}</td>
            <td>{{ row.unassigned | number }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import DatePicker from "v-calendar/lib/components/date-picker.umd";
import { BFormGroup, BFormInput, BCard, BBadge } from "bootstrap-vue";
import DepositReturnTable from "./DepositReturnTable.vue";

export default {
  components: {
    DatePicker,
    BFormGroup,
    BFormInput,
    BCard,
    DepositReturnTable,
    BBadge,
  },
  props: ["form"],
  data() {
    return {
      account_list: [],
    };
  },
  watch: {
    form: {
      handler(newVal, oldVal) {
        this.load_data();
      },
      deep: true, // Set this to true if 'form' is an object and you want to watch its properties
    },
  },
  created() {
    this.load_data();
  },
  computed: {},
  methods: {
    async load_data() {
      await this.$http({
        method: "POST",
        url: `/account/book-summary`,
        data: this.form,
      }).then(async (x) => {
        const data = x.data.data;
        this.account_list = data;
      });
    },
    amount_from_type(obj, type) {
      const payment = obj.find((p) => p._id.payment_type === type);
      return payment ? payment.amount : null;
    },

    count_from_type(obj, type) {
      const payment = obj.find((p) => p._id.payment_type === type);
      return payment ? payment.count : 0;
    },
  },
  filters: {
    number: function (x) {
      if (x != null) {
        let rounded = Number(x).toFixed(2);

        // Remove the decimal part if it's .00
        if (rounded.endsWith(".00")) {
          rounded = rounded.substring(0, rounded.length - 3);
        }
        return rounded.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      } else {
        return x;
      }
    },
    thai_text(text) {
      const dict = [
        {
          th: "ค่าเช่า",
          en: "rental",
        },
        {
          th: "เงินวางประกัน",
          en: "deposit",
        },
        {
          th: "ค่าส่ง",
          en: "shipping",
        },
      ];
      const match = dict.find((item) => item.en === text);
      return match ? match.th : text;
    },
  },
};
</script>
