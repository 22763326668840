<template>
  <div>
    <div class="row">
      <div class="col">
        <b-overlay variant="dark" :show="loading" rounded="sm">
          <b-card>
            <!-- ยอดวันนี้ -->
            <div class="d-flex justify-content-between align-items-center">
              <h4>รายรับ</h4>
              <div>
                <span style="font-size: 20px" class="mr-1">
                  <span>
                    <b-badge :variant="badgeVariant">
                      {{ payment.total_income | number }} /
                      {{ (order.rental_price + order.shipping_price) | number }}
                    </b-badge>
                  </span>
                </span>
                <b-button
                  size="sm"
                  variant="primary"
                  @click="collapses1 = !collapses1"
                >
                  <feather-icon
                    icon="ChevronUpIcon"
                    size="16"
                    v-if="!collapses1"
                  />
                  <feather-icon
                    icon="ChevronDownIcon"
                    size="16"
                    v-if="collapses1"
                  />
                </b-button>
              </div>
            </div>
            <b-collapse v-model="collapses1">
              <div class="table-responsive">
                <table class="table table-hover">
                  <thead>
                    <tr>
                      <th></th>
                      <th>Order</th>
                      <th>Payment</th>
                      <th>Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        ค่าเช่า
                        <feather-icon
                          title="ถ้าไม่เท่าเช็คล่างสุด เช็ค หัก ณ ที่จ่าย กับ ค่าเช่าต่อ"
                          v-b-tooltip.focus.v-primary
                          icon="InfoIcon"
                          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                        />
                      </td>
                      <td>{{ order.rental_price | number }}</td>
                      <td>{{ payment.rental_price | number }}</td>
                      <td>
                        <b-badge
                          variant="success"
                          v-if="status.rental_price.complete"
                          >PASS</b-badge
                        >
                        <div v-if="!status.rental_price.complete">
                          <b-badge class="mr-1" variant="danger">
                            NOT Complete
                          </b-badge>
                          <b-badge variant="danger">
                            {{ status.rental_price.diff | number }}
                          </b-badge>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        ค่าเช่าต่อ
                        <feather-icon
                          title="ถ้าไม่เท่า อาจจเป็นที่ลูกค้ายังไม่ได้คืนเลยไม่ได้รับเงิน"
                          v-b-tooltip.focus.v-primary
                          icon="InfoIcon"
                          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                        />
                      </td>
                      <td>( {{ order.rental_price_extend | number }} )</td>
                      <td>( {{ payment.rental_price_extend | number }} )</td>
                      <td>
                        <b-badge
                          variant="success"
                          v-if="status.rental_price_extend.complete"
                          >PASS</b-badge
                        >
                        <div v-if="!status.rental_price_extend.complete">
                          <b-badge class="mr-1" variant="danger">
                            NOT Complete
                          </b-badge>
                          <b-badge variant="danger">
                            {{ status.rental_price_extend.diff }}
                          </b-badge>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>ค่าส่ง</td>
                      <td>{{ order.shipping_price | number }}</td>
                      <td>{{ payment.shipping_price | number }}</td>
                      <td>
                        <b-badge
                          variant="success"
                          v-if="status.shipping_price.complete"
                        >
                          PASS
                        </b-badge>
                        <div v-if="!status.shipping_price.complete">
                          <b-badge class="mr-1" variant="danger">
                            NOT Complete
                          </b-badge>
                          <b-badge variant="danger">
                            {{ status.shipping_price.diff }}
                          </b-badge>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>หัก ณ ที่จ่าย</td>
                      <td>{{ order.withholding_tax_price | number }}</td>
                      <td>-</td>
                      <td>-</td>
                    </tr>
                    <tr>
                      <td>ส่วนลด</td>
                      <td>{{ order.discount_price | number }}</td>
                      <td>-</td>
                      <td>-</td>
                    </tr>
                    <tr>
                      <td>ไม่มารับของ</td>
                      <td>{{ order.no_show_price | number }}</td>
                      <td>{{ payment.no_show_price | number }}</td>
                      <td>
                        <b-badge
                          variant="success"
                          v-if="status.no_show_price.complete"
                        >
                          PASS
                        </b-badge>
                        <div v-if="!status.no_show_price.complete">
                          <b-badge class="mr-1" variant="danger">
                            NOT Complete
                          </b-badge>
                          <b-badge variant="danger">
                            {{ status.no_show_price.diff }}
                          </b-badge>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        เงินประกันเข้ารวม
                        <feather-icon
                          title="ทุกบัญชี ทุกรูปแบบชำระเงิน"
                          v-b-tooltip.focus.v-primary
                          icon="InfoIcon"
                          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                        />
                      </td>
                      <td>{{ order.deposit_price | number }}</td>
                      <td>{{ payment.deposit_price | number }}</td>
                      <td>
                        <b-badge
                          variant="warning"
                          v-if="!status.deposit_price.complete"
                        >
                          {{ status.deposit_price.diff | number }}
                        </b-badge>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        รวม
                        <feather-icon
                          title="ค่าเช่า รวมซ้ายขวาต้องเท่ากัน"
                          v-b-tooltip.focus.v-primary
                          icon="InfoIcon"
                          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                        />
                      </td>
                      <td>{{ order.total_income | number }}</td>
                      <td>{{ payment.total_income | number }}</td>
                      <td>
                        <b-badge
                          variant="success"
                          v-if="status.total_income.complete"
                        >
                          PASS
                        </b-badge>
                        <div v-if="!status.total_income.complete">
                          <b-badge class="mr-1" variant="danger">
                            NOT Complete
                          </b-badge>
                          <b-badge variant="danger">
                            {{ status.total_income.diff }}
                          </b-badge>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </b-collapse>
            <hr />
            <!-- เงินประกันรับ -->
            <div class="d-flex justify-content-between align-items-center">
              <h4>เงินประกัน</h4>
              <div>
                <span style="font-size: 20px" class="mr-1">
                  <span>
                    <b-badge
                      :variant="
                        depositVariant(
                          payment.deposit_price,
                          deposit.order_deposit_price
                        )
                      "
                    >
                      {{ payment.deposit_price | number }} /
                      {{ deposit.order_deposit_price | number }}
                    </b-badge>
                  </span>
                </span>

                <b-button
                  size="sm"
                  variant="primary"
                  @click="collapses2 = !collapses2"
                >
                  <feather-icon
                    icon="ChevronUpIcon"
                    size="16"
                    v-if="!collapses2"
                  />
                  <feather-icon
                    icon="ChevronDownIcon"
                    size="16"
                    v-if="collapses2"
                  />
                </b-button>
              </div>
            </div>
            <b-collapse v-model="collapses2">
              <table class="table table-hover mt-2">
                <tbody>
                  <tr v-for="(xx, index) in bookbank_data_pickup" :key="index">
                    <td>{{ xx.display_name }}</td>
                    <td>{{ xx.netAmount | number }}</td>
                  </tr>
                </tbody>
              </table>
            </b-collapse>
            <hr />
            <!-- เงินประกันคืน -->
            <div class="d-flex justify-content-between align-items-center">
              <h4>เงินประกันคืน</h4>
              <div>
                <span style="font-size: 20px" class="mr-1">
                  <span>
                    <b-badge
                      :variant="
                        depositVariant(
                          deposit.order_deposit_price_return -
                            un_return_deposit,
                          deposit.order_deposit_price_return
                        )
                      "
                    >
                      {{
                        (deposit.order_deposit_price_return - un_return_deposit)
                          | number
                      }}
                      /
                      {{ deposit.order_deposit_price_return | number }}
                    </b-badge>
                  </span>
                </span>

                <b-button
                  v-if="
                    depositVariant(
                      deposit.order_deposit_price_return - un_return_deposit,
                      deposit.order_deposit_price_return
                    ) != 'light-success'
                  "
                  size="sm"
                  variant="primary"
                  @click="collapses3 = !collapses3"
                >
                  <feather-icon
                    icon="ChevronUpIcon"
                    size="16"
                    v-if="!collapses3"
                  />
                  <feather-icon
                    icon="ChevronDownIcon"
                    size="16"
                    v-if="collapses3"
                  />
                </b-button>
              </div>
            </div>
            <b-collapse
              v-model="collapses3"
              v-if="
                depositVariant(
                  deposit.order_deposit_price_return - un_return_deposit,
                  deposit.order_deposit_price_return
                ) != 'light-success'
              "
            >
              <table class="table table-hover mt-2">
                <tbody>
                  <tr v-for="(xx, index) in bookbank_data_return" :key="index">
                    <td>{{ xx.display_name }}</td>
                    <td>{{ xx.netAmountReturn }}</td>
                  </tr>
                </tbody>
              </table>
            </b-collapse>
            <hr />

            <!-- รับอุปกรณ์ -->
            <div class="d-flex justify-content-between align-items-center">
              <h4>รับอุปกรณ์</h4>
              <div>
                <span style="font-size: 20px" class="mr-1">
                  <span>
                    <b-badge
                      :variant="
                        countVariant(count_pickup.complete, count_pickup.all)
                      "
                    >
                      {{ count_pickup.complete }} / {{ count_pickup.all }}
                    </b-badge>
                  </span>
                </span>
              </div>
            </div>
            <hr />
            <!-- คืนอุปกรณ์ -->
            <div class="d-flex justify-content-between align-items-center">
              <h4>คืนอุปกรณ์</h4>
              <div>
                <span style="font-size: 20px" class="mr-1">
                  <span>
                    <b-badge
                      :variant="
                        countVariant(count_return.complete, count_return.all)
                      "
                    >
                      {{ count_return.complete }} / {{ count_return.all }}
                    </b-badge>
                  </span>
                </span>
              </div>
            </div>
            <hr />
            <!-- ชำระเงิน -->
            <div class="d-flex justify-content-between align-items-center">
              <h4>ชำระเงิน</h4>
              <div>
                <span style="font-size: 20px" class="mr-1">
                  <span>
                    <b-badge
                      :variant="
                        countVariant(count_payment.complete, count_payment.all)
                      "
                    >
                      {{ count_payment.complete }} / {{ count_payment.all }}
                    </b-badge>
                  </span>
                </span>
              </div>
            </div>
          </b-card>
        </b-overlay>
      </div>
    </div>
  </div>
</template>

<script>
import Ripple from "vue-ripple-directive";
import {
  BCard,
  BButton,
  BBadge,
  BCollapse,
  VBTooltip,
  BOverlay,
} from "bootstrap-vue";
import BCardActions from "@core/components/b-card-actions/BCardActions.vue";
export default {
  components: {
    BCard,
    BButton,
    BBadge,
    BCollapse,
    BCardActions,
    BOverlay,
  },
  mounted() {},
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },
  computed: {
    badgeVariant() {
      // Define your conditions here
      if (this.status.total_income.complete === true) {
        return "light-success";
      } else if (
        this.status.total_income.complete === false &&
        this.status.rental_price_extend.diff === this.status.total_income.diff
      ) {
        return "light-warning";
      } else {
        return "light-danger";
      }
    },
  },
  data() {
    return {
      loading: false,
      collapses1: false,
      collapses2: false,
      collapses3: false,

      order: {},
      payment: {},
      count_pickup: {
        complete: 0,
        all: 0,
      },
      count_return: {
        complete: 0,
        all: 0,
      },
      count_payment: {
        complete: 0,
        all: 0,
      },
      status: {
        rental_price: {
          complete: false,
          diff: 0,
        },
        rental_price_extend: {
          complete: false,
          diff: 0,
        },
        shipping_price: {
          complete: false,
          diff: 0,
        },
        no_show_price: {
          complete: false,
          diff: 0,
        },
        deposit_price: {
          complete: false,
          diff: 0,
        },
        total_income: {
          complete: false,
          diff: 0,
        },
      },
      deposit: {},
      bookbank_data_pickup: [],
      bookbank_data_return: [],
      un_return_deposit: 0,
    };
  },
  props: ["form"],
  watch: {
    form: {
      handler(newVal, oldVal) {
        this.load_data();
        this.load_order_status_count();
        this.load_deposit_data();
      },
      deep: true,
    },
  },
  methods: {
    async load_data() {
      this.loading = true;
      await this.$http({
        method: "POST",
        url: `/account/summary`,
        data: this.form,
      })
        .then(async (x) => {
          this.loading = false;
          const data = x.data.data;
          this.order = data.order;
          this.payment = data.payment;
          this.status = data.status;
          this.$root.$emit("account-reload", data);
        })
        .catch((e) => {
          this.loading = false;
          console.log("load_data error", e);
        });
    },

    async load_order_status_count() {
      this.loading = true;
      /// pickup section
      await this.$http({
        method: "POST",
        url: `/order/list/count`,
        data: {
          focus_date_start: this.form.date.start,
          focus_date_end: this.form.date.end,
          side: "pickup",
        },
      })
        .then((x) => {
          this.loading = false;
          const zz = x.data.data;
          if (zz.totalCount.length) {
            this.count_pickup.all = zz.totalCount[0].total;
            this.count_pickup.complete = zz.pickup_status.reduce(
              (acc, item) => {
                if (item._id === "received" || item._id === "returned") {
                  acc += item.count;
                }
                return acc;
              },
              0
            );

            let paid_count = zz.is_paid.find((item) => item._id === true);
            paid_count = paid_count ? paid_count.count : 0;

            let un_paid_count = zz.is_paid.find((item) => item._id === false);
            un_paid_count = un_paid_count ? un_paid_count.count : 0;

            this.count_payment.all = paid_count + un_paid_count;
            this.count_payment.complete = paid_count;
          }
        })
        .catch((e) => {
          this.loading = false;
          console.log("load_order_status_count error", e);
        });

      /// return section
      await this.$http({
        method: "POST",
        url: `/order/list/count`,
        data: {
          focus_date_start: this.form.date.start,
          focus_date_end: this.form.date.end,
          side: "return",
        },
      }).then((x) => {
        this.loading = false;
        const zz = x.data.data;

        if (zz.totalCount.length) {
          this.count_return.all = zz.totalCount[0].total;

          this.count_return.complete = zz.pickup_status.reduce((acc, item) => {
            if (item._id === "received" || item._id === "returned") {
              acc += item.count;
            }
            return acc;
          }, 0);
        }
      });
    },

    async load_deposit_data() {
      this.loading = true;
      await this.$http({
        method: "POST",
        url: `/account/deposit-summary`,
        data: this.form,
      }).then(async (x) => {
        this.loading = false;
        const zz = x.data.data;
        this.deposit = zz;
        this.bookbank_data_pickup = zz.bookbank_data.filter(
          (item) => item.netAmount !== 0
        );
        this.bookbank_data_return = zz.bookbank_data.filter(
          (item) => item.netAmountReturn !== 0
        );

        this.un_return_deposit = zz.bookbank_data.reduce(
          (accumulator, item) => {
            return accumulator + item.netAmountReturn;
          },
          0
        );
      });
    },
    countVariant(complete, all) {
      if (complete === all) {
        return "light-success";
      } else {
        return "light-danger";
      }
    },

    depositVariant(complete, all) {
      if (complete === all) {
        return "light-success";
      } else {
        return "light-warning";
      }
    },
  },
  created() {
    this.load_data();
    this.load_order_status_count();
    this.load_deposit_data();
  },
  filters: {
    number: function (x) {
      if (x != null) {
        let rounded = Number(x).toFixed(2);

        // Remove the decimal part if it's .00
        if (rounded.endsWith(".00")) {
          rounded = rounded.substring(0, rounded.length - 3);
        }
        return rounded.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      } else {
        return x;
      }
    },
  },
};
</script>
