<template>
  <div>
    <b-form-group label="วันที่">
      <date-picker
        v-model="form"
        :masks="{ input: 'D/M/YYYY' }"
        :model-config="modelConfig"
        mode="date"
        @input="datepicked"
      >
        <template v-slot="{ inputValue, togglePopover }">
          <b-form-group>
            <!-- :mode="date-time" -->
            <b-form-input
              class="border px-2 py-1 rounded"
              :value="inputValue"
              @click="togglePopover()"
            />
          </b-form-group>
        </template>
      </date-picker>
    </b-form-group>
  </div>
</template>

<script>
import { BFormGroup, BFormInput } from "bootstrap-vue";
import DatePicker from "v-calendar/lib/components/date-picker.umd";
import moment from "moment";
import "moment-timezone";

export default {
  components: {
    DatePicker,
    BFormGroup,
    BFormInput,
  },
  data() {
    return {
      form: moment().format("YYYY-MM-DD"), //,
      modelConfig: {
        type: "string",
        mask: "YYYY-MM-DD", // Uses 'iso' if missing
      },
    };
  },
  created() {
    console.log(moment().format("YYYY-MM-DD"));
    this.datepicked();
  },
  methods: {
    datepicked() {
      this.$emit("date_picked", this.form);
    },
  },
};
</script>
